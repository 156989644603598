import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import {Avatar, Box, IconButton, Typography, useTheme} from "@mui/material";
import AppIcon from "../../asserts/images/app_icon.png"
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import axios from "../../api/axios";
import {GifBoxOutlined} from "@mui/icons-material";
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Прогресс");
  const [userData, setUserData] = useState({
      firstName: '',
      lastName: '',
      role: ''
  })
    const getUsers = async () => {
        try {
            const response = await axios.get('api/education_plan/get_users_data', {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': 'http://localhost:3000',
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem("userData")).accessToken}`,
                },
                withCredentials: true
            });
            console.log('Данные в сайдбаре', response?.data);
            setUserData(prevState => ({ ...prevState, firstName:  response?.data.first_name, lastName:  response?.data.last_name, role:  response?.data.role }));
        } catch (err) {
            if (!err?.response) {
                // setLoadingStatus('error');
            }
        }
    };
  useEffect(() => {
      getUsers();
  }, [])

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <img src={AppIcon} style={{height: '40px', width: '40px'}}/>
                <Typography variant="h4" color={colors.grey[100]}>
                  Tutor Toolkit
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                  <Avatar sx={{ bgcolor: colors.blueAccent[500], width: '100px', height: '100px', borderRadius: "50%" }}>{userData.firstName[0]}</Avatar>
                {/*<img*/}
                {/*  alt="profile-user"*/}
                {/*  width="100px"*/}
                {/*  height="100px"*/}
                {/*  src={`../../assets/user.png`}*/}
                {/*  style={{ cursor: "pointer", borderRadius: "50%" }}*/}
                {/*/>*/}
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h3"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                    {userData.firstName}
                    <br/>
                    {userData.lastName}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {userData.role === 'student'? 'Студент' : 'Преподаватель'}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
            >
              Основное
            </Typography>
            <Item
              title="Материалы"
              to="/main"
              icon={<Inventory2OutlinedIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Образовательный план"
              to="/edu"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Список студентов"
              to="/contacts"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/*<Item*/}
            {/*  title="Invoices Balances"*/}
            {/*  to="/invoices"*/}
            {/*  icon={<ReceiptOutlinedIcon />}*/}
            {/*  selected={selected}*/}
            {/*  setSelected={setSelected}*/}
            {/*/>*/}

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Дополнительное
            </Typography>
            <Item
              title="Добавить студента"
              to="/form"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Расписание"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/*<Item*/}
            {/*  title="FAQ Page"*/}
            {/*  to="/faq"*/}
            {/*  icon={<HelpOutlineOutlinedIcon />}*/}
            {/*  selected={selected}*/}
            {/*  setSelected={setSelected}*/}
            {/*/>*/}

            {/*<Typography*/}
            {/*  variant="h6"*/}
            {/*  color={colors.grey[300]}*/}
            {/*  sx={{ m: "15px 0 5px 20px" }}*/}
            {/*>*/}
            {/*  Charts*/}
            {/*</Typography>*/}
            {/*<Item*/}
            {/*  title="Bar Chart"*/}
            {/*  to="/bar"*/}
            {/*  icon={<BarChartOutlinedIcon />}*/}
            {/*  selected={selected}*/}
            {/*  setSelected={setSelected}*/}
            {/*/>*/}
            {/*<Item*/}
            {/*  title="Pie Chart"*/}
            {/*  to="/pie"*/}
            {/*  icon={<PieChartOutlineOutlinedIcon />}*/}
            {/*  selected={selected}*/}
            {/*  setSelected={setSelected}*/}
            {/*/>*/}
            {/*<Item*/}
            {/*  title="Line Chart"*/}
            {/*  to="/line"*/}
            {/*  icon={<TimelineOutlinedIcon />}*/}
            {/*  selected={selected}*/}
            {/*  setSelected={setSelected}*/}
            {/*/>*/}
            {/*<Item*/}
            {/*  title="Geography Chart"*/}
            {/*  to="/geography"*/}
            {/*  icon={<MapOutlinedIcon />}*/}
            {/*  selected={selected}*/}
            {/*  setSelected={setSelected}*/}
            {/*/>*/}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
